import { useState } from "react";
import bullionStats from "./assets/bullioStats.png";
import Moment from "react-moment";
import {
  Box,
  Button,
  Fade,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Marquee from "react-fast-marquee";
import "./App.css";
import Carousel from "react-material-ui-carousel";
import axios from "axios";
import { useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment-timezone";
import io from "socket.io-client";
import mobileError from "./assets/mobileErrorImg.png";
import BullionLogo from "./assets/bullion stats.png";

import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const { makeStyles } = require("@mui/styles");
const socket = io("https://demosocket.bullionview.com/");

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 6,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  outline: "none",
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "#000000",
  borderRadius: "10px",
  p: 8,
  outline: "none",
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "100vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    backgroundColor: "black",
    padding: "1rem 3rem 2rem 3rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    paddingTop: "4rem",
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "2fr 3fr 1fr",
    gridTemplateAreas: `
        'banner' 
        'spotRate'
        'stat'
      `,
  },
  banner: {
    gridArea: "banner",
    borderTopLeftRadius: "3rem",
    borderTopRightRadius: "3rem",
    marginBottom: "1rem",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    overflow: "hidden",
  },
  spotRate: {
    gridArea: "spotRate",
    backgroundColor: "#BEC0C0",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  stat: {
    gridArea: "stat",
    backgroundColor: "black",
    // border: "1px solid",
    borderBottomLeftRadius: "3rem",
    borderBottomRightRadius: "3rem",
    color: "#fff",
  },
  bullionStatsImg: {
    width: "150px",
    height: "auto",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1.5fr 4.5fr 1fr",
    gridTemplateAreas: `
        'header' 
        'table'
        'updates'
      `,
  },
  header: {
    gridArea: "header",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leenLogoImg: {
    width: "15%",
    height: "auto",
  },
  table: {
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 6fr",
    gridTemplateAreas: `
        'tabeHeader' 
        'tableContent'
      `,
    gap: "1em",
    height: "93%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "honeydew",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#16341B",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    overflowX: "scroll",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "flex-start",
    fontSize: "2vw",
    paddingLeft: "2rem",
    // border: "1px solid",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "15%",
    flexGrow: 1,
    gridArea: "tableRow1",
    border: "1px dotted #5C3F32",
    borderRadius: "5px",
    background: "black",
    border: "1px  solid",
    display: "flex",
  },
  updates: {
    gridArea: "updates",
    backgroundColor: "black",
    // border: "1px  solid",
    borderBottomLeftRadius: "3rem",
    borderBottomRightRadius: "3rem",
    color: "#fff",
  },
  updatesHeader: {
    background: "#BEC0C0",
    color: "#000",
    borderBottom: "1px solid #white",
  },
  updatesContent: {
    marginTop: 10,
    color: "#fff",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Leen = () => {
  const { tv } = useSelector((state) => ({ ...state }));

  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousSilver, setPreviousSilver] = useState();

  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [previousGold, setPreviousGold] = useState();

  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);

  useEffect(() => {
    socket.on("connect", () => {});
    socket.on("disconnect", () => {});
    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });
    socket.on("gold-rate-change", (data) => {
      setIsMarketOpen(data.data.isMarketOpen);
      setPreviousGold(gold);
      setGold({
        ask: data.data.ask,
        bid: data.data.bid,
        highPrice: data.data.highPrice,
        lowPrice: data.data.lowPrice,
      });
    });
    socket.on("silver-rate-change", (data) => {
      setPreviousSilver(silver);
      setSilver({
        ask: data.data.ask,
        bid: data.data.bid,
        highPrice: data.data.highPrice,
        lowPrice: data.data.lowPrice,
      });
    });
    return () => {
      // Remove event listeners here
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
      socket.off("gold-rate-change");
      socket.off("silver-rate-change");
    };
  });

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });

    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );
        console.log(dubaiTime.format("H"));
        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        // const timeRemaining = duration - elapsedTime;
        if (elapsedTime <= 0) {
          // Timer completed
          clearInterval(timerId);
          console.log("Timer completed!");
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const [commodities, setCommodities] = useState();

  const allCommodities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/allCommodities/63abe5e06e40dbc92528ebde`
      );
      setCommodities(response.data.commodities);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    allCommodities();
  }, []);

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const getSpread = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getSpread/63abe5e06e40dbc92528ebde`
      );
      setSpread(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSpread();
  }, []);

  function commodityCalculation(
    commodityPrice,
    goldAskSpread,
    premuimPerOunce,
    unit,
    purity,
    additionalCharge
  ) {
    const usdToAed = 3.674;
    let value =
      (((commodityPrice + goldAskSpread + premuimPerOunce) * usdToAed) /
        31.1035) *
        purity *
        unit +
      additionalCharge;

    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  }

  const [ratio, setRatio] = useState([]);

  const getRatio = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/getRatio`
      );
      setRatio(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRatio();
  }, []);

  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetchAllBanner();
  }, []);

  const fetchAllBanner = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/getAllImages/63abe5e06e40dbc92528ebde`,
      {
        method: "GET",
        headers: {
          "Content-Type": "image/png",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res) && res?.length > 0) {
          setBanners(res);
        }
      });
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    fetchAllNews();
  }, []);

  const fetchAllNews = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/allNewses/63abe5e06e40dbc92528ebde`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (Array.isArray(res) && res?.length > 0) {
          setNews(res);
        }
      });
  };

  const classes = useStyles();

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = (date) => {
    return date.toLocaleString([], {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  const progress = Number(ratio[0]?.golPercentage);

  const buyersColor = "#2B85DC";
  const sellersColor = "#DE5B56";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };
  const isTvScreen = useMediaQuery("(min-width: 1000px)");

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const logout = async () => {
    Cookies.set("tv", "");
    dispatch({
      type: "LOGOUT",
    });
    await fetch("https://testbackend.bullionview.com/tvScreenLogout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tv),
    });
    navigate("/");
  };

  const [isBlurActive, setIsBlurActive] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [expDays, setExpDays] = useState(0);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);

  function countDaysBetweenDates(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const daysDiff = start.getDate() - end.getDate();
    return daysDiff;
  }
  async function triggerExpire() {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  async function tvLimitCheck() {
    if (tv?.liveUsers >= tv?.loginCount) {
      let a = new Date().toISOString();
      let b = new Date(tv?.package.expire_date).toISOString();
      let daysBetweenDates = countDaysBetweenDates(a, b);
      setExpDays(daysBetweenDates);
      setIsBlurActive(true);
      handleOpen2();
    }
  }

  useEffect(() => {
    tvLimitCheck();
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {isTvScreen ? (
        <Box className={`${isBlurActive ? classes.blur : ""}`}>
          <Box className={classes.mainContainer}>
            {!isMarketOpen && (
              <Box
                sx={{
                  position: "fixed",
                  top: "0",
                  left: "-125px",
                  right: "0",
                  bottom: "0",
                  width: "895px",
                  height: "531px",
                  overflow: "hidden",
                  zIndex: "99",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "10%",
                    transform: "rotate(-35deg)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "black",
                    color: "#FFFFFF",
                    fontSize: "2vw",
                    fontWeight: "bold",
                    padding: "20px",
                    lineHeight: 1.5,
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Marquee delay="2">
                    {remainingTime && (
                      <p style={{ marginLeft: "90px" }}>
                        Market closed! Opens on{" "}
                        {formatRemainingTime(remainingTime)}
                      </p>
                    )}
                  </Marquee>
                </div>
              </Box>
            )}

            <Box className={classes.leftPart}>
              <Box className={classes.banner}>
                <Carousel
                  animation="fade" // Set the animation type to slide
                  navButtonsAlwaysVisible={false} // Show navigation buttons always
                  interval={3000}
                  indicatorContainerProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  sx={{
                    maxHeight: "27vh",
                    // borderRadius: "20px",
                    borderTopLeftRadius: "3rem",
                    borderTopRightRadius: "3rem",
                  }}
                  transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                  autoPlay
                >
                  {banners?.map((banner, i) => (
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "27vh",
                        objectFit: "cover",
                        // borderRadius: "20px",
                        borderTopLeftRadius: "3rem",
                        borderTopRightRadius: "3rem",
                      }}
                      src={banner.imageUrl}
                      alt="banner"
                    />
                  ))}
                </Carousel>
              </Box>
              <Box className={classes.spotRate}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    // px: 2,
                    // gap: "2rem",
                    height: "23%",
                    borderBottom: "1px dotted white",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      fontWeight: "bold",
                      // minWidth: "100px",
                      // width: "7.5vw",
                      // pl: 1,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    SPOT RATE
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      // width: "35%",
                      // width: "100px",
                      // width: "7.5vw",
                      // px: 1.5,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: "#fff",
                        background: "#16341B",
                        px: "7px",
                        display: "inline-block",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                      BID
                    </Typography>
                    <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                      // width: "35%",
                      // width: "100px",
                      // width: "7.5vw",
                      // px: 1.5,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                    }}
                  >
                    <Box
                      sx={{
                        color: "#fff",
                        background: "#16341B",
                        px: "7px",
                        display: "inline-block",
                        width: "fit-content",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                      ASK
                    </Typography>
                    <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "42%",
                    display: "flex",
                    // justifyContent: "space-around",
                    // px: 3,
                    // gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "2vw",
                      fontWeight: "bold",
                      marginBottom: 4,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    GOLD
                  </Typography>

                  <Box className={classes.spotRateBoxGoldRow2Col1}>
                    <Box
                      sx={{
                        background:
                          gold?.bid < previousGold?.bid
                            ? "#DE5B56"
                            : gold?.bid > previousGold?.bid
                            ? "#5FA147"
                            : "#2F2E2C",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "6.3vh",
                        display: "flex",
                        alignItems: "center",
                        px: 1.5,
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                        {(
                          Number(spread.goldBidSpread) + Number(gold.bid)
                        ).toFixed(2)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#DE5B56",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                      </Box>
                      <Typography style={{ fontSize: "1.3vw" }}>
                        {(
                          Number(spread.goldLowSpread) + Number(gold.lowPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.spotRateBoxGoldRow2Col2}>
                    <Box
                      sx={{
                        background:
                          gold?.ask < previousGold?.ask
                            ? "#DE5B56"
                            : gold?.bid > previousGold?.bid
                            ? "#5FA147"
                            : "#2F2E2C",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "6.3vh",
                        display: "flex",
                        alignItems: "center",
                        px: 1.5,
                      }}
                    >
                      <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                        {(
                          Number(spread.goldAskSpread) + Number(gold.ask)
                        ).toFixed(2)}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#5FA147",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>
                          High
                        </Typography>
                      </Box>
                      <Typography style={{ fontSize: "1.3vw" }}>
                        {(
                          Number(spread.goldHighSpread) + Number(gold.highPrice)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    height: "42%",
                    display: "flex",
                    // px: 5,
                    // gap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.6vw",
                      marginBottom: 4,
                      flexBasis: 0,
                      flexGrow: 1,
                      flexShrink: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    SILVER
                  </Typography>

                  <Box className={classes.spotRateBoxSilverRow2Col1}>
                    <Box
                      sx={{
                        background:
                          silver?.bid < previousSilver?.bid
                            ? "#DE5B56"
                            : silver?.bid > previousSilver?.bid
                            ? "#5FA147"
                            : "#2F2E2C",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        px: 1.5,
                        // display: "inline-block",
                        // width: "fit-content",
                        width: "7.3vw",
                        height: "5vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.6vw" }}>
                        &nbsp;&nbsp;
                        {(
                          Number(spread.silverAskSpread) + Number(silver.bid)
                        ).toFixed(3)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#DE5B56",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>Low</Typography>
                      </Box>
                      <Typography style={{ fontSize: "1vw" }}>
                        {(
                          Number(spread.silverLowSpread) +
                          Number(silver.lowPrice)
                        ).toFixed(3)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.spotRateBoxSilverRow2Col2}>
                    <Box
                      sx={{
                        background:
                          silver?.ask < previousSilver?.ask
                            ? "#DE5B56"
                            : silver?.ask > previousSilver?.ask
                            ? "#5FA147"
                            : "#2F2E2C",
                        border: "1px solid #fff",
                        color: "#fff",
                        borderRadius: "5px",
                        px: 1.5,
                        // display: "inline-block",
                        // width: "fit-content",
                        // width: "110px",
                        // height: "40px",
                        width: "7.3vw",
                        height: "5vh",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.6vw" }}>
                        &nbsp;&nbsp;
                        {(
                          Number(spread.silverBidSpread) + Number(silver.ask)
                        ).toFixed(3)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        // pl: 1,
                      }}
                    >
                      <Box
                        sx={{
                          background: "#5FA147",
                          color: "#fff",
                          px: 1,
                          display: "inline-block",
                          width: "fit-content",
                        }}
                      >
                        <Typography style={{ fontSize: "1vw" }}>
                          High
                        </Typography>
                      </Box>
                      <Typography style={{ fontSize: "1vw" }}>
                        {(
                          Number(spread.silverHighSpread) +
                          Number(silver.highPrice)
                        ).toFixed(3)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.stat}>
                {ratio?.map((value, idx) => (
                  <>
                    <Box
                      key={idx}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        px: 5,
                        py: 1,
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
                      <Typography sx={{ color: "#DE5B56", fontSize: "1.3vw" }}>
                        {value.ratio}
                      </Typography>
                      <Typography sx={{ fontSize: "1.3vw" }}>
                        SELLERS
                      </Typography>
                    </Box>
                    <Box sx={{ width: "90%", px: 4 }}>
                      <LinearProgress
                        variant="determinate"
                        value={progress}
                        style={progressStyle}
                        sx={{
                          "& .MuiLinearProgress-bar": barStyle,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        px: 7,
                        py: 1,
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography sx={{ fontSize: "1.3vw", color: "#2B85DC" }}>
                        {value.golPercentage}%
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <a href="https://www.bullionstats.com/">
                          <img
                            className={classes.bullionStatsImg}
                            src={bullionStats}
                            alt="bullionStats"
                          />
                        </a>
                      </Box>
                      <Typography sx={{ fontSize: "1.3vw", color: "#DE5B56" }}>
                        {100 - value.golPercentage}%
                      </Typography>
                    </Box>
                  </>
                ))}
              </Box>
            </Box>
            <Box className={classes.rightPart}>
              <Box className={classes.header}>
                <img
                  className={classes.leenLogoImg}
                  src={BullionLogo}
                  alt="leenLogo"
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    Bullion
                  </Typography>
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: "2rem",
                      fontWeight: "bold",
                      marginTop: "-10px",
                    }}
                  >
                    Stats
                  </Typography>
                </Box>

                {/* <Button
                  sx={{
                    color: "white",
                    fontSize: "2rem",
                    fontWeight: "bold",
                  }}
                  onClick={() => logout()}
                >
                  Logout
                </Button> */}
                <Box
                  sx={{
                    display: "flex",
                    color: "#fff",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <AccessTimeIcon style={{ fontSize: "3vw" }} />
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.8vw",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {formatTime(time)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <CalendarMonthIcon
                    style={{ color: "#fff", fontSize: "3vw" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#fff",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.8vw",
                      }}
                    >
                      {formatDate(time)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.4vw",
                        mt: -1,
                        ml: 0.5,
                      }}
                    >
                      {formatDay(time)}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: 4,
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Commodity
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: 2,
                      // background: "white",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Weight
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: 4,
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Buy
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "left",
                      paddingLeft: 4,
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    Sell
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box key={idx} className={classes.tableRow1}>
                        <Typography
                          style={{ fontSize: "1.5vw", fontWeight: "bold" }}
                          className={classes.tableRowColumn}
                        >
                          {words.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {word}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.commodity_title === "Gold TEN TOLA"
                            ? ""
                            : Number(commodity.purity) === 0.9999
                            ? 9999
                            : Number(commodity.purity) * 1000}
                          {/* {Number(commodity.purity) === 0.9999
                      ? 9999
                      : Number(commodity.purity) * 1000} */}
                        </Typography>
                        <Typography
                          style={{
                            padding: 0,
                            fontSize: "2vw",
                            fontWeight: "bold",
                            justifyContent: "center",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {commodity.unit}
                          {commodity.unitLabel}
                        </Typography>
                        <Typography
                          style={{ fontSize: "2vw", fontWeight: "bold" }}
                          className={classes.tableRowColumn}
                        >
                          {commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver.bid
                              : gold.bid,
                            commodity.commodity_title === "Silver"
                              ? spread.silverBidSpread
                              : spread.goldBidSpread,
                            commodity.buy_premium,
                            commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "TTB"
                              ? 116.64
                              : 1,
                            commodity.purity,
                            commodity.buy_charge
                          )}
                        </Typography>
                        <Typography
                          style={{ fontSize: "2vw", fontWeight: "bold" }}
                          className={classes.tableRowColumn}
                        >
                          {commodityCalculation(
                            commodity.commodity_title === "Silver"
                              ? silver.ask
                              : gold.ask,
                            commodity.commodity_title === "Silver"
                              ? spread.silverAskSpread
                              : spread.goldAskSpread,
                            commodity.premium,
                            commodity.unitLabel === "KG"
                              ? 1000
                              : commodity.unitLabel === "TTB"
                              ? 116.64
                              : 1,
                            commodity.purity,
                            commodity.charges
                          )}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className={classes.updates}>
                <Box className={classes.updatesHeader}>
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      px: 1,
                    }}
                  >
                    B U L L I O N S T A T S &nbsp; U P D A T E S
                  </Typography>
                </Box>
                <Box className={classes.updatesContent}>
                  <marquee behavior="" direction="">
                    <Box sx={{ display: "flex" }}>
                      {news?.map((item, index) => {
                        return (
                          <Box
                            key={item._id}
                            style={{
                              fontSize: "1.5vw",
                              fontFamily: "poppins",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <span style={{ fontWeight: "bold" }}>
                              {item.newsTitle}
                            </span>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            <span>{item.newsDetails}</span>
                            <span
                              style={{
                                paddingRight: "50px",
                                paddingLeft: "50px",
                              }}
                            >
                              {index === news.length - 1 ? "" : "|"}
                            </span>
                          </Box>
                        );
                      })}
                    </Box>
                  </marquee>
                </Box>
              </Box>
            </Box>
          </Box>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  variant="h4"
                  sx={{ color: "red", fontFamily: "poppins", fontSize: 30 }}
                >
                  Your Subscription Expiring Soon
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    fontSize: 15,
                    fontFamily: "poppins",
                    textAlign: "center",
                    width: 400,
                  }}
                >
                  Your subscription will be expiring on &nbsp;
                  <span>
                    <Moment
                      element="span"
                      style={{ width: "100%" }}
                      format="MMM Do YYYY"
                    >
                      {tv?.package.expire_date}
                    </Moment>
                  </span>
                  . Please subscribe to continue our service
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    mt: 4,
                    width: "100px",
                    textTransform: "none",
                    background: "3DA1EA",
                    "&:hover": {
                      backgroundColor: "3DA1EA",
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open2}
            closeAfterTransition
          >
            <Fade in={open2}>
              <Box sx={style2}>
                <Typography
                  variant="h4"
                  sx={{
                    color: "white",
                    fontFamily: "poppins",
                    fontSize: 20,
                    width: 500,
                    textAlign: "center",
                    lineHeight: 1.3,
                  }}
                >
                  {` Your TV screen limit has been exceeded (${expDays}) . If you wish to
                  increase the screen limit please contact +971 55 225 5763`}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 3,
                      width: "100px",
                      textTransform: "none",
                      background: "3DA1EA",
                      "&:hover": {
                        backgroundColor: "3DA1EA",
                      },
                    }}
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
            background: "#16341B",
          }}
        >
          <img src={mobileError} alt="Image" style={{ width: "50vw" }} />
          <Typography
            sx={{
              color: "white",
              fontSize: "1em",
              marginTop: 2,
              marginRight: 3,
              marginLeft: 3,
              textAlign: "center",
              fontFamily: "poppins",
            }}
          >
            Mobile Preview is not available; please open in a TV, desktop, or
            Web browser.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default Leen;
