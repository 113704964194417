import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedIn from "./routes/LoggedIn";
import NotLoggedIn from "./routes/NotLoggedIn";
import Leen from "./Leen";
import Login from "./Login";

function App() {
  return (
    <div>
      <ToastContainer />
      <Routes>
        {/* <Route element={<LoggedIn />}> */}
        <Route path="/" exact element={<Leen />} />
        {/* </Route> */}
        {/* <Route element={<NotLoggedIn />}>
          <Route path="/login" element={<Login />} />
        </Route> */}
      </Routes>
    </div>
  );
}

export default App;
