import { Box, Button, TextField, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Logo from "././assets/Logo.png";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100vh",
    backgroundColor: "#FFF",
    display: "grid",
    gridTemplateColumns: "1.3fr 1fr",
    gridTemplateAreas: `
    'leftPart rightPart'
  `,
  },
  leftPart: {
    // background: "#000000",
    background:
      "linear-gradient(135deg, #14181D 2.08%, #3E3E3E 69.15%, #3E3E3E 94.04%)",
  },
  rightPart: {
    background: "#FFFFFF",
  },
  rightElem: {
    background: "#FFFFFF",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "30%",
    height: "auto",
  },
  error_msg: {
    color: "red",
    fontSize: "1rem",
    margin: 0,
    fontFamily: "poppins",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pb: "2rem",
  },
}));
function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const loginSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      setIsLogin(true);
      await fetch("https://testbackend.bullionview.com/tvScreenLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json()) // Parse the response as JSON
        .then((data) => {
          if (data.status) {
            dispatch({ type: "LOGIN", payload: data });
            Cookies.set("tv", JSON.stringify(data));
            navigate("/");
            action.resetForm();
          } else {
            setIsLogin(false);
            toast.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Error:", error); // Handle any errors
        });
    },
  });

  return (
    <>
      <ToastContainer />

      <Box className={classes.main}>
        <Box className={classes.leftPart}>
          <Box className={classes.logoBox}>
            <img className={classes.logo} src={Logo} alt="logo" />
          </Box>
        </Box>
        <Box className={classes.rightPart}>
          <Box className={classes.rightElem}>
            <Box>
              <Box sx={{ pb: "2.1rem" }}>
                <Typography sx={{ fontSize: "2rem", fontWeight: "bold" }}>
                  Hello Again!
                </Typography>
                <Typography sx={{ fontSize: "1.1rem" }}>
                  Welcome Back
                </Typography>
              </Box>
              <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ pb: "1rem" }}>
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      width: "100%",
                      height: "60px",
                      borderRadius: "110px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                    }}
                  >
                    <PersonIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
                    <TextField
                      variant="outlined"
                      placeholder="User name"
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                      }}
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </Box>
                </Box>
                <Box sx={{ pb: "1rem" }}>
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      width: "100%",
                      height: "60px",
                      borderRadius: "110px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                    }}
                  >
                    <LockIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
                    <TextField
                      variant="outlined"
                      type="password"
                      placeholder="Password"
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                      }}
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </Box>
                </Box>
                {formik.touched.email && formik.errors.email && (
                  <p className={classes.error_msg}>{formik.errors.email}</p>
                )}
                {formik.touched.password && formik.errors.password && (
                  <p className={classes.error_msg}>{formik.errors.password}</p>
                )}
                <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
                  <Button
                    type="submit"
                    disabled={isLogin}
                    sx={{
                      background: "#0469CA",
                      width: "100%",
                      height: "60px",
                      borderRadius: "110px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        background: "#0469CA",
                      },
                    }}
                  >
                    {isLogin ? (
                      <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                        Loading
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                        Login
                      </Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;
